document.addEventListener("DOMContentLoaded", () => {
  function showMore() {
    const button = document.querySelector("#doctor-about .about-btn");
    const list = document.querySelector(
      "#doctor-about .about-description-hidden"
    );
    if (button) {
      button.addEventListener("click", () => {
        if (list.classList.contains("show")) {
          button.querySelector("span").textContent = "Читати більше";
          list.style.maxHeight = null;
          list.classList.remove("show");
        } else {
          button.querySelector("span").textContent = "Сховати";
          list.style.maxHeight = list.scrollHeight + "px";
          list.classList.add("show");
        }
      });
    }
  }

  showMore();
});
