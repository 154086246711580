//
function accordeon() {
  const btns = document.querySelectorAll(".question-item");
  const texts = document.querySelectorAll(".question-item-text-box");
  if (btns.length > 0 && texts.length > 0) {
    let activeIndex = 0;
    btns[activeIndex].classList.add("active");
    texts[activeIndex].classList.add("active");

    btns.forEach((btn, index) => {
      btn.addEventListener("click", () => {
        btns[activeIndex].classList.remove("active");
        texts[activeIndex].classList.remove("active");
        btn.classList.add("active");
        texts[index].classList.add("active");
        activeIndex = index;
      });
    });
  }
}
document.addEventListener("DOMContentLoaded", accordeon);
