document.addEventListener("DOMContentLoaded", () => {
  function showMore() {
    const button = document.querySelector(".question-button");
    const list = document.querySelector(".question-list-hidden");
    const text = document.querySelectorAll(".question-item-text");
    const maxHeight = Math.max(
      ...Array.from(text).map((item) => item.scrollHeight)
    );
    if (button) {
      button.addEventListener("click", () => {
        if (list.classList.contains("show")) {
          button.textContent = "Переглянути більше";
          list.style.maxHeight = null;
          list.classList.remove("show");
        } else {
          button.textContent = "Сховати";
          list.style.maxHeight = list.scrollHeight + maxHeight + "px";
          list.classList.add("show");
        }
      });
    }
  }

  showMore();
});
