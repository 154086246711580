// import 'intl-tel-input/build/css/intlTelInput.min.css';

import "./utils";

import "./swiper";
import "./popup";
import "./accordeon";
import "./mask";
import "./formMask";
import "./formSubmit";
import "./questionAccordeon";
import "./showMore";
import "./showMoreAbout";
import "./assistanceVideo";
import "./closeBtn";

import "../css/main.scss";
