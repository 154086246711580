document.addEventListener("DOMContentLoaded", () => {
  const assistanceVideo = document.querySelectorAll(
    ".assistance-video-swiper .swiper-slide"
  );
  const assistanceItem = document.querySelectorAll(".assistance-item");

  // Функція для оновлення видимості елементів
  const updateActiveItem = () => {
    // Знайти елемент з класом "swiper-slide-active"
    assistanceVideo.forEach((slide, index) => {
      if (slide.classList.contains("swiper-slide-active")) {
        // Показати відповідний елемент assistanceItem
        assistanceItem.forEach((item, itemIndex) => {
          item.style.display = index === itemIndex ? "block" : "none";
        });
      }
    });
  };

  // Викликати функцію при завантаженні сторінки
  updateActiveItem();

  // Додати спостерігач для змін у DOM (якщо слайди будуть змінюватися через Swiper)
  const observer = new MutationObserver(updateActiveItem);

  // Спостерігати за змінами класу кожного слайду
  assistanceVideo.forEach((slide) => {
    observer.observe(slide, { attributes: true, attributeFilter: ["class"] });
  });
});
