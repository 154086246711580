import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const reviewsSwiper = new Swiper(".reviews-swiper", {
  autoHeight: true,
  slidesPerView: "auto",
  spaceBetween: 11,
  loop: true,
  navigation: {
    nextEl: ".reviews-next",
    prevEl: ".reviews-prev",
  },
  pagination: {
    el: ".reviews-pagination",
    clickable: true,
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 11,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 32,
    },
  },
});

const assistanceSwiper = new Swiper(".assistance-swiper", {
  autoHeight: true,
  slidesPerView: "auto",
  spaceBetween: 11,
  loop: true,
  navigation: {
    nextEl: ".assistance-next",
    prevEl: ".assistance-prev",
  },
  breakpoints: {
    768: {
      slidesPerView: 3,
      spaceBetween: 11,
    },
    992: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
  },
});

const videoSwiper = new Swiper(".assistance-video-swiper", {
  autoHeight: true,
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  navigation: {
    nextEl: ".assistance-video-next",
    prevEl: ".assistance-video-prev",
  },
  breakpoints: {
    1170: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  },
});

const blogSwiper = new Swiper(".blog-swiper", {
  autoHeight: true,
  slidesPerView: 1,
  spaceBetween: 11,
  loop: true,
  navigation: {
    nextEl: ".blog-next",
    prevEl: ".blog-prev",
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 11,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  },
});

const operationsSwiper = new Swiper(".operations-swiper", {
  autoHeight: true,
  slidesPerView: "auto",
  spaceBetween: 11,
  loop: true,
  navigation: {
    nextEl: ".operations-next",
    prevEl: ".operations-prev",
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 11,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
  },
});

const qualificationSwiper = new Swiper(".qualification-swiper", {
  autoHeight: true,
  slidesPerView: "auto",
  spaceBetween: 11,
  loop: true,
  navigation: {
    nextEl: ".qualification-next",
    prevEl: ".qualification-prev",
  },
  pagination: {
    el: ".qualification-pagination",
    clickable: true,
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 11,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  },
});
